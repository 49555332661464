<template>
    <div class="list-wrap">
        <!--<div class="selected">
            <div v-for="key in 3"  class="mentor">
                <div class="profile"><img src="@/assets/image/rebuild/default.png"></div>
                <div class="name">김현수</div>
                <div class="cancel"></div>
            </div>
        </div>-->
        <div class="mentor-list">
            <div
                    v-for="member in memberData"
                    :key="`member${member.mb_no}`"
                    :class="{'active' : hasSelMentor(member)}"
                    :disblaed="disabledList(member)"
                    @click="clickMentor(member)"
                    class="mentor"> <!--class="active" 추가시 선택한거-->
                <div class="profile">
                    <img :src="returnProfileImage(member)">
                </div>
                <div class="info">
                    <div class="i_top">
                        {{member.mb_name}}
                        <span class="job">{{member.mb_job}}</span>
                    </div>
                    <div class="company">{{member.mb_belong}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import imageOption from "@/mixins/imageOption";
    import VuxAlert from "@/mixins/vux-alert";
    export default {
        name: "MemberListSelectLayout",
        mixins: [imageOption, VuxAlert],
        inject: ['mentorSelList', 'setMentorList'],
        props: {
            memberData: {
                type: Array,
                default: () => {return []},
            },
            total: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                // mentors: [],
            }
        },
        computed: {
            mentors() {
                return this.mentorSelList.hasOwnProperty('list') ? this.mentorSelList.list : [];
            },
        },
        mounted() {
            // this.mentors = this.mentorSelList;
        },
        methods: {
            hasSelMentor(mentor) {
                return this.mentors.findIndex(member => member.mb_no === mentor.mb_no) > -1;
            },
            returnProfileImage(member) {
                return member.hasOwnProperty('Profile') && member.Profile.length > 0 ? `${member.Profile[0].org_url}?${this.setImageOptions(82, 82, 'png')}` : ''
            },
            clickMentor(member) {
                let mentors = this.mentors;
                if(this.hasSelMentor(member)) {
                    mentors = this.popMentor(member, mentors);
                }else{
                    mentors = this.pushMentor(member, mentors);
                }
                this.setMentorList(mentors);
            },
            popMentor(member, mentors) {
                let mentorIndex = mentors.findIndex(mentor => mentor.mb_no === member.mb_no);
                mentors.splice(mentorIndex, 1);
                return mentors;
            },
            pushMentor(member, mentors) {
                if(this.mentors.length >= 3) {
                    this.errorAlert('최대 3명까지 선택이 가능합니다.')
                }else{
                    mentors.push(member);
                }
                return mentors;
            },
            disabledList(member) {
                return !this.hasSelMentor(member) && this.mentors.length >= 3;
            },
        },
    }
</script>

<style scoped>

</style>